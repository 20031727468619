<template>
    <loading v-if="submitting">

    </loading>
    <div class="row" v-else>
        <div class="col">
            <div class="row">
                <div class="col">
                    <div class="table-responsive">
                        <table class="table table-sm table-striped">
                            <thead class="table-light">
                                <tr>
                                    <th><input type="checkbox" @change="selectFiltered()"></th>
                                    <th>Name</th>
                                    <th>Company</th>
                                    <th>Email</th>
                                    <th>Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="4">
                                        <div class="row">
                                            <div class="col-auto">
                                                <button class="btn btn-sm btn-primary"
                                                        type="button"
                                                        @click.prevent="changePage(-1)">
                                                    <i class="bi bi-chevron-left"></i>                                
                                                </button>

                                            </div>
                                            <div class="col">
                                                <input class="form-control form-control-sm" 
                                                        placeholder="Search" 
                                                        type="search" 
                                                        v-model="textFilter">
                                            </div>
                                            <div class="col-auto">
                                                {{ filteredAttendees.length }} / {{ attendees.length }} ({{selectedItemsCount}})
                                            </div>
                                            <div class="col-auto">
                                                <button type="button"
                                                        class="btn-sm btn btn-danger"
                                                        @click="deselectAllItems">
                                                    <i class="bi bi-x-circle"></i>
                                                </button>
                                            </div>
                                            <div class="col-auto">
                                                <div class="input-group">


                                                    <span class="form-text">
                                                        {{ page + 1 }} / {{ totalPages }}
                                                    </span>

                                                    <select class="form-select form-select-sm ms-2"
                                                            v-model="showTop">
                                                        <option v-for="filter in showTopFilters"
                                                                :value="filter.Amount">
                                                            {{filter.Name}}
                                                        </option>
                                                    </select>


                                                </div>

                                            </div>
                                            <div class="col-auto">
                                                <button class="btn btn-sm btn-primary"
                                                        type="button"
                                                        @click.prevent="changePage(1)">
                                                    <i class="bi bi-chevron-right"></i>                                
                                                </button>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <span class="badge"
                                                :class="[categoryFilter.includes(category.Code) ? 'bg-primary' : 'bg-secondary']" 
                                                v-for="(category, idx) in showDetails.AttendeeCategories" 
                                                @click="toggleCategoryFilter(category.Code)">
                                            {{category.Name}}
                                        </span>
                                    </td>
                                </tr>
                                <tr v-for="attendee in currentPage"
                                    :key="attendee.Code"
                                    @click="attendee.Selected = !attendee.Selected"
                                    :class="[{'table-primary': attendee.Selected}]">
                                    <td>
                                        <input type="checkbox" v-model="attendee.Selected">
                                    </td>
                                    <td>
                                        {{attendee.Details.DisplayName}}
                                    </td>
                                    <td>
                                        <span v-if="attendee.Details.ContactInfo">
                                            {{attendee.Details.ContactInfo.SubTitle}}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="attendee.Details.ContactInfo">
                                            {{attendee.Details.PrimaryEmail}}
                                        </span>
                                    </td>
                                    <td>
                                        {{attendee.Code}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Common from '../common'
import Token from '../authentication/token'

export default {
    mixins: [
        Common, Token
    ],

    props: ['showDetails', 'attendeesSelected'],

    data() {
        return {
            attendeeList: [],
            attendees: [],
            categoryFilter: [],
            textFilter: '',

            page: 0,
            showTop: 25,
            showTopFilters: [{
                Name: "10",
                Amount: 10
            },
            {
                Name: "25",
                Amount: 25
            },{
                Name: "50",
                Amount: 50
            },{
                Name: "100",
                Amount: 100
            },{
                Name: "All",
                Amount: 999999
            },]
        }
    },

    watch: {
        showTop() {
            this.page = 0;
        },

        textFilter() {
            this.page = 0;
        }
    },

    computed: {
        selectedItemsCount() {
            return this.attendees.filter(x=>x.Selected).length;
        },

        totalPages() {
            return Math.ceil(this.filteredAttendees.length / this.showTop);
        },

        currentPage() {
            let toReturn = this.filteredAttendees;
            let startIndex = this.page * this.showTop;
            let endIndex = startIndex + this.showTop;
            toReturn = toReturn.slice(startIndex, endIndex);

            return toReturn;
        },

        filteredAttendees() {

            let toReturn = this.attendees;

            if(this.textFilter && this.textFilter.length > 0) {
                let filter = this.textFilter.toLowerCase().trim();
                toReturn = toReturn.filter(x => 
                    (x.Details.DisplayName || '').toLowerCase().includes(filter)
                    || (x.Details.FirstName || '').toLowerCase().includes(filter)
                    || (x.Details.LastName || '').toLowerCase().includes(filter)
                    || (x.Details.ContactInfo.SubTitle || '').toLowerCase().includes(filter)
                    || x.Code.toLowerCase().includes(filter));
            }

            if(this.categoryFilter.length > 0) {
                toReturn = toReturn.filter(x => {

                    if(x.CategoryLookup.length == 0) {
                        return false;
                    }

                    for(const filter in this.categoryFilter) {
                        if(!x.CategoryLookup.find(c => c == this.categoryFilter[filter])) {
                            return false;
                        }
                    }

                    return true;

                });
                
                
                //x.CategoryLookup.some(c => this.categoryFilter.includes(c)));
            }

            return toReturn;
        }
    },

    methods: {
        changePage(amount) {
            let newPageNumber = this.page + amount;

            if(newPageNumber < 0) {
                newPageNumber = this.totalPages - 1;
            }
            else if(newPageNumber >= this.totalPages) {
                newPageNumber = 0;
            }

            this.page = newPageNumber;
        },


        deselectAllItems() {
            this.filteredAttendees.forEach(element => {
                element.Selected = false;
            });            
        },

        selectAllCategories() {
            this.categoryFilter.splice(0, this.categoryFilter.length);
            this.categoryFilter.push(...this.showDetails.AttendeeCategories.map(x=>x.Code));
        },

        selectFiltered() {
            this.filteredAttendees.forEach(element => {
                element.Selected = !element.Selected;
            });
        },
        
        toggleCategoryFilter(categoryCode) {
            this.page = 0;
            if(this.categoryFilter.includes(categoryCode)) {
                this.categoryFilter.splice(this.categoryFilter.indexOf(categoryCode), 1);
            } else {
                this.categoryFilter.push(categoryCode);
            }
        },

        getSelectedAttendees() {
            return this.attendees.filter(x=>x.Selected);
        },

        async build() {
            this.submitting = true;

            try {
                let r = await this.tryGet(`/api/show/${this.showDetails.Code}/attendees`);
                this.attendeeList = r.Result;

                this.attendees.push(...this.attendeeList.map(a => {
                    return {
                        Details: a,
                        Code: a.Code,
                        Selected: false,
                        CategoryLookup: a.Categories.map(x=>x.Code)
                    }
                }));

                if(this.attendeesSelected && this.attendeesSelected.length > 0){
                    for(var i = 0; i < this.attendeesSelected.length; i++){
                        let index = this.attendees.findIndex(x => x.Code == this.attendeesSelected[i]);
                        this.attendees[index].Selected = true;
                    }
                }


            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        }
    },

    mounted() {
        this.build();
    }
}
</script>